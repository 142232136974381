import Flickity from "flickity";
import { TweenLite } from "gsap";

export class Slideshow {
    
    private _slideshows: Array<Flickity>;

    constructor() {    

    }


    public init() {

        this._slideshows = [];
        const slideshow_containers: NodeListOf<HTMLElement> = document.querySelectorAll( '.c-selected, .c-project-slideshow' );
        for ( let i: number = 0; i < slideshow_containers.length; i++ ) {

            const slideshow_element: HTMLElement = slideshow_containers[ i ].querySelector( '.c-selected__projects, .c-project-slideshow__slideshow' );
            if ( slideshow_element ) {

                const slideshow: Flickity = new Flickity( slideshow_element, {
                    
                    autoPlay: false,
                    pageDots: false,
                    // @ts-ignore
                    pauseAutoPlayOnHover: false,
                    prevNextButtons: false,
                    selectedAttraction: 0.2,
                    friction: 0.8,
                    setGallerySize: false,
                    wrapAround: true
                    
                } );
                this._slideshows.push( slideshow );
                
                const images: NodeListOf<HTMLImageElement> = slideshow_containers[ i ].querySelectorAll( '.c-selected__project__image, .c-project-slideshow__image' );
                for ( let j: number = 0; j < images.length; j++ ) {
                    
                    images[ j ].addEventListener( 'load', () => this.handleImageLoaded( slideshow ) );
                    
                }
                
                const counter_number: HTMLElement = slideshow_containers[ i ].querySelector( '.c-selected__counter__number, .c-project-slideshow__counter__number' );
                if ( counter_number ) {
                    
                    slideshow.on( 'select', () => this.handleSlideshowSelect( counter_number, slideshow ) );
                    
                }

                this.setupCursor( slideshow );
                
            }

        }


    }


    private handleImageLoaded( slideshow: Flickity ) {
        
        console.log('handleImageLoaded');
        slideshow.resize();

    }


    private handleSlideshowSelect( text_element: HTMLElement, slideshow: Flickity ) {

        text_element.innerText = `${slideshow.selectedIndex + 1}`;
        
    }


    private setupCursor( slideshow: Flickity ) {

        const cursor_element: HTMLElement = document.querySelector( '.c-cursor' );
        if ( cursor_element ) {

            const hitfield_left: HTMLElement = document.querySelector( '.c-slideshow-hitfield--left' );
            if ( hitfield_left ) {

                hitfield_left.addEventListener( 'mouseenter', ( event: MouseEvent ) => {
                    TweenLite.set( cursor_element, { rotation: -180 } );
                } );

                hitfield_left.addEventListener( 'mouseleave', ( event: MouseEvent ) => {
                    TweenLite.set( cursor_element, { rotation: -135 } );
                } );

                hitfield_left.addEventListener( 'click', () => {
                    
                    slideshow.previous();

                });
            }

            const hitfield_right: HTMLElement = document.querySelector( '.c-slideshow-hitfield--right' );
            if ( hitfield_right ) {
                
                hitfield_right.addEventListener( 'mouseenter', ( event: MouseEvent ) => {
                    TweenLite.set( cursor_element, { rotation: 0 } );
                } );
                
                hitfield_right.addEventListener( 'mouseleave', ( event: MouseEvent ) => {
                    TweenLite.set( cursor_element, { rotation: -135 } );
                } );
                
                hitfield_right.addEventListener( 'click', ( event: Event ) => {
                    
                    slideshow.next();

                });
            }

        }

    }


    private handleMouseMoveLeft( event: MouseEvent, cursor_element: HTMLElement ) {

        // TweenLite.set( cursor_element, { x: event.clientX, y: event.clientY } );
        // console.log( event.clientX, event. clientY );

    }


    public resize() {

        for ( let i: number = 0; i < this._slideshows.length; i++ ) {

            // this._slideshows[ i ].resize();

        }
    }
    
}