import { Globals } from "../helpers/Globals";
import * as Colcade from  "../../../node_modules/colcade/colcade.js"; 

// declare class ColcadeOptions extends Object {
//     columns: string;
//     items: string;
// }

// declare class Colcade {
//     constructor( grid: Element|string, options: ColcadeOptions )
// }

export class ProjectGrid {

    private _container: HTMLElement;
    private _colcade: Colcade;
    private _mouse_moved: boolean = false;

    constructor() {

    }


    public init() {

        this._container = document.querySelector( '.c-projects__projects' );

        if (this._container) {

            this.layoutColumns();
            this.setupIntersectionObserver();

            window.addEventListener( 'mousemove', () => this.handleMouseMove() );

            // window.addEventListener( 'load', () => this.layoutColumns() );

        }

    }


    private handleMouseMove() {

        if ( !this._mouse_moved ) {

            this._mouse_moved = true;
            document.body.classList.add( 's-mouse-moved' );

        }
    }


    private layoutColumns() {

        const columns: number = this.getColumns();
        if ( columns > 1 ) {

            var grid = document.querySelector( '.grid' );
            this._colcade = new Colcade( grid, {
                columns: '.grid-col',
                items: '.grid-item'
            } );
            // @ts-ignore
            // const col_heights: Array<number> = new Array( columns ).fill( 0 );
            
            // if ( this._container ) {
                
            //     const projects: NodeListOf<HTMLElement> = this._container.querySelectorAll( '.c-projects__project' );
            //     for ( let i: number = 0; i < projects.length; i++ ) {
                    
            //         const order = i % columns;
            //         col_heights[ order ] += projects[ i ].clientHeight;
                    
            //     }
                
            // }
            
            // this._container.style.height = Math.max(...col_heights) + 1 + 'px';
            // this._container.style.height = Math.max(...col_heights) + 0 + 'px';

        } else if ( this._colcade ) {

            this._colcade.destroy();

        }

    }


    private getColumns(): number {

        if ( window.innerWidth > Globals.TABLET_LARGE_W ) {

            return 4;

        } else if ( window.innerWidth > Globals.TABLET_W ) {

            return 2;

        }

        return 1;

    }


    private setupIntersectionObserver() {

        const projects: NodeListOf<HTMLElement> = this._container.querySelectorAll( 'a.c-projects__project' );
        if ( projects ) {

            const logo: HTMLElement = document.querySelector( '.c-header__logo__link' )
            const logo_rect: ClientRect = logo.getBoundingClientRect();

            const options: Object = {
                // @ts-ignore    
                rootMargin: `-${logo_rect.top + logo_rect.height}px 0px 100px 0px`,
                threshold: 1
            };

            const projects_observer: IntersectionObserver = new IntersectionObserver( ( entries, observer ) => {
                
                for (let i: number = 0; i < entries.length; i++ ) {
                    
                    if ( entries[ i ].isIntersecting ) {
                        
                        entries[ i ].target.classList.add( 's-visible' );
                        
                    } else {
                        
                        entries[ i ].target.classList.remove( 's-visible' );
                        
                    }
                    
                }
                
            }, options );
            
            
            for ( let i: number = 0; i < projects.length; i++ ) {
                
                projects_observer.observe( projects[ i ] );
                
            }

            const load_more_element: HTMLElement = document.querySelector( '.c-append-more' );
            if ( load_more_element ) {

                const options: Object = {
                    // @ts-ignore    
                    rootMargin: `0px 0px 0px 0px`,
                    threshold: .01
                };

                const load_more_observer: IntersectionObserver = new IntersectionObserver( ( entries, observer ) => {

                    if ( window.innerWidth < Globals.TABLET_W ) {

                        for (let i: number = 0; i < entries.length; i++ ) {
                            
                            if ( entries[ i ].isIntersecting ) {
                                
                                const target: HTMLElement = <HTMLElement>entries[ i ].target;
                                for ( let j: number = 0; j < 1; j++ ) {
                                    
                                    for ( let i: number = 0; i < projects.length; i++ ) {
                                        
                                        this._container.insertBefore( projects[ i ].cloneNode(true), target);
                                        const new_node: HTMLElement = this._container.querySelector( 'a.c-projects__project:last-of-type' );
                                        new_node.classList.add( 'c-projects__project--clone' );
                                        if (new_node) projects_observer.observe( new_node );
                                        
                                    }
                                    
                                }
                                
                            }
                        }

                    }

                } );

                load_more_observer.observe( load_more_element );

            }

        }

    }


    public resize() {

        if (this._container) this.layoutColumns();

    }

}