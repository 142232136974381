import { Globals } from "../helpers/Globals";

export class IntersectText {


    private _observer: IntersectionObserver;
    private _project_title_observer: IntersectionObserver;
    private _intersect_header_element: HTMLElement;
    private _targets: NodeListOf<HTMLElement>;
    private _project_title_targets: NodeListOf<HTMLElement>;
    private _options: Object;
    private _project_title_options: Object;

    constructor() {

    }


    public init() {

        this._intersect_header_element = document.querySelector( '.c-intersect' );
        this._project_title_targets = document.querySelectorAll( '.c-projects__project__title span' );
        this._targets = document.querySelectorAll( '.c-project__content span, .c-project__header__inner span' );

        this.reconfigureObservers();

    }


    private reconfigureObservers() {

        if ( this._intersect_header_element ) {

            const intersect_element_rect: Object = this._intersect_header_element.getClientRects();
              
            this._options = {
                // @ts-ignore    
                rootMargin: `-${ intersect_element_rect[ 0 ].top + intersect_element_rect[ 0 ].height * 2}px 0px 0px 0px`,
                threshold: 0
                
            };

            this.setupObserver( this._observer, this._targets, this._options);
            
            
            let margin: string;
            if (window.innerWidth > Globals.TABLET_W) {
                
                margin = `-${ intersect_element_rect[ 0 ].height * 3.5 }px 0px 0px 0px`;
            
            } else {
                // @ts-ignore
                margin = `-${ parseInt(window.innerHeight * .5) }px 0px 0px 0px`;

            }

            this._project_title_options = {
                // @ts-ignore
                rootMargin: margin,
                threshold: 0
            };

            this.setupObserver( this._project_title_observer, this._project_title_targets, this._project_title_options);
            
        }

    }


    private setupObserver(observer: IntersectionObserver, targets: NodeListOf<HTMLElement>, options: Object) {
        
        observer = new IntersectionObserver( ( entries, observer ) => {
            
            for (let i: number = 0; i < entries.length; i++ ) {
                
                if ( entries[ i ].isIntersecting ) {
                    
                    entries[ i ].target.classList.add( 's-visible' );
                    
                } else {
                    
                    entries[ i ].target.classList.remove( 's-visible' );
                    
                }
                
            }
            
        }, options);
        
        
        for ( let i: number = 0; i < targets.length; i++ ) {
            
            observer.observe( targets[ i ] );

        }

    }


    private destroyObserver() {

        if ( this._observer ) {

            for ( let i: number = 0; i < this._targets.length; i++ ) {
                
                this._observer.unobserve( this._targets[ i ] );

            }

        }

        if ( this._project_title_observer ) {

            for ( let i: number = 0; i < this._project_title_targets.length; i++ ) {

                this._project_title_observer.unobserve( this._project_title_targets[ i ] );

            }

        }

    }


    public resize() {

        this.destroyObserver();

        if ( this._intersect_header_element ) {

            this.reconfigureObservers();

        }
        
    }
    
}