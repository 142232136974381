export class TextOverflow {

    private _breakpoint: string = '';
    private _observers: Array<IntersectionObserver> = [];
    private _targets: NodeListOf<HTMLElement>;
    private _targets_about: NodeListOf<HTMLElement>;
    private _targets_slide_titles: NodeListOf<HTMLElement>;
    private _targets_slide_codes: NodeListOf<HTMLElement>;

    private _logo_width: number;
    private _counter_width: number;

    
    constructor() {

    }


    public init() {

        const logo: HTMLElement = document.querySelector( '.c-header__logo__link' )
        const logo_rect: ClientRect = logo.getBoundingClientRect();

        if ( document.body.classList.contains( 'home' ) ) {
            
            this._logo_width = logo_rect.left + logo_rect.width + 10;
            this._targets_slide_titles = document.querySelectorAll( '.c-selected__project__title-container .c-text-overflow .c-word-container' );
            // const logo_options: Object = this.getOptions( `0px 0px 0px -${this._logo_width}px` );
            const slide_title_options: Object = this.getOptions( `0px 0px 0px -50%` );
            this.setupObserver( this._targets_slide_titles, slide_title_options );
            
            const counter: HTMLElement = document.querySelector( '.c-selected__counter' )
            const counter_rect: ClientRect = counter.getBoundingClientRect();
            this._counter_width = counter_rect.left + counter_rect.width + 10;
            this._targets_slide_codes = document.querySelectorAll( '.c-selected__project__code.c-text-overflow .c-word-container' );
            // const counter_options: Object = this.getOptions( `0px 0px 0px -${this._counter_width}px` );
            const slide_codes_options: Object = this.getOptions( `0px 0px 0px -50%` );
            this.setupObserver( this._targets_slide_codes, slide_codes_options );
            
            this._targets = document.querySelectorAll( '.c-header-overlay__inner .c-text-overflow .c-word-container' );

        } else {

            this._targets = document.querySelectorAll( '.c-text-overflow .c-word-container' );

        }

        this._targets_about = document.querySelectorAll( '.c-header-overlay .c-text-overflow .c-word-container, .c-header-overlay__text span' );
        // const logo_options: Object = this.getOptions( `0px 0px 0px -${this._logo_width}px` );
        const about_options: Object = this.getOptions( `-${logo_rect.top * 1.85 + logo_rect.height}px 0px 200px 0px` );
        this.setupObserver( this._targets_about, about_options );

    }


    private setupObserver( targets: NodeListOf<HTMLElement>, options: Object ) {
        
        const observer: IntersectionObserver = new IntersectionObserver( ( entries, observer ) => {
            
            for (let i: number = 0; i < entries.length; i++ ) {

                if ( entries[ i ].isIntersecting ) {
                    
                    entries[ i ].target.classList.add( 's-visible' );
                    
                } else {
                    
                    entries[ i ].target.classList.remove( 's-visible' );
                    
                }
                
            }
            
        }, options);
        
        
        for ( let i: number = 0; i < targets.length; i++ ) {

             observer.observe( targets[ i ] );

        }

        this._observers.push( observer );

    }


    private getOptions( margin: string ): Object {

        return {
            // @ts-ignore    
            rootMargin: margin,
            threshold: 1
            
        };
    }


    public detroy() {



    }

}