//Keep typescript from complaining about touch test.
declare class DocumentTouch {}
declare class InfiniteScroll {}

export class Globals {

    public static DEBUG: boolean = false;

    public static SITE_WRAPPER: HTMLDivElement = document.getElementById('SiteWrapper') as HTMLDivElement;
    public static TEMPLATE_LAYER = document.getElementById('TemplateLayer') as HTMLDivElement;
    public static IS_TOUCH_DEVICE: boolean = 'ontouchstart' in window || ((window as any).DocumentTouch && document instanceof DocumentTouch); // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
    public static IS_IE: boolean = false;

    public static MOBILE_W: number = 0;
    public static MOBILE_MEDIUM_W: number = 482;
    public static TABLET_W: number = 722;
    public static TABLET_LARGE_W: number = 962;
    public static LAPTOP_W: number = 1202;
    public static DESKTOP_W: number = 1440;

}