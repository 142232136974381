import { TweenLite } from "gsap";

export class Cursor {

    constructor() {


    }


    public init() {

        const cursor_element: HTMLElement = document.querySelector( '.c-cursor' );
        if ( cursor_element ) {
            
            window.addEventListener( 'mousemove', ( event: MouseEvent ) => this.handleMouseMove( event, cursor_element ) );

            const iframes: NodeListOf<HTMLIFrameElement> = document.querySelectorAll( 'iframe' );
            for ( let i: number = 0; i < iframes.length; i++ ) {

                iframes[ i ].addEventListener( 'mouseenter', () => cursor_element.style.display = "none" );
                iframes[ i ].addEventListener( 'mouseleave', () => cursor_element.style.display = "block" );

            }

        }

        const links: NodeListOf<HTMLAnchorElement> = document.querySelectorAll( 'a' );
        for ( let i: number = 0; i < links.length; i++ ) {

            links[ i ].addEventListener( 'mouseenter', () => {
                
                cursor_element.classList.add( 'c-cursor--hover' );
                // TweenLite.set( cursor_element, { rotation: -90 } );

            } );

            links[ i ].addEventListener( 'mouseleave', () => {

                cursor_element.classList.remove( 'c-cursor--hover' );
                // TweenLite.set( cursor_element, { rotation: -135 } );

            } );

        }
    }


    private handleMouseMove( event: MouseEvent, cursor_element: HTMLElement ) {

        TweenLite.set( cursor_element, { opacity: 1, x: event.clientX, y: event.clientY } );
        // console.log( event.clientX, event. clientY );

    }
}