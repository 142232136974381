// import '@babel/polyfill';
import { Slideshow } from './modules/Slideshow';
// import { HistoryManager } from './andreasoby/controllers/HistoryManager';
// import { PageTransition } from './andreasoby/controllers/PageTransition';
import { ProjectGrid } from './modules/ProjectGrid';
import { Header } from './modules/Header';
import { IntersectText } from './modules/IntersectText';
import { TextOverflow } from './modules/TextOverflow';
import { Cursor } from './modules/Cursor';

class Main {

    // private _history_manager: HistoryManager;
    // private _page_transition: PageTransition;
    
    private _body: HTMLBodyElement;
    private _cursor: Cursor;
    private _header: Header;
    private _project_grid: ProjectGrid;
    private _slideshows: Slideshow;
    private _intersect_text: IntersectText;
    private _text_overflow: TextOverflow;

    constructor() {

        document.addEventListener('DOMContentLoaded', () => this.init());

    }


    private init() {

        this.setupVars();

        if ( !this._body.classList.contains( 'wp-admin' ) ) {

            // this._history_manager = new HistoryManager( [ '.c-main', '.c-header__nav' ], null );
            // this._page_transition = new PageTransition();
            // this._object_fit_images = new ObjectFitFallback();

            this.setupListeners();
            this.setupContent();
            
            this.checkIfTouch();

        }
        
    }


    private setupVars() {

        this._body = <HTMLBodyElement>document.querySelector('body');

    }


    private checkIfTouch() {

        window.addEventListener( 'touchstart', () => {

            document.body.classList.add( 'touch-device' );

        }, false );

    }


    private setupContent() {

        this._header = new Header();
        this._cursor = new Cursor();
        this._slideshows = new Slideshow();
        this._project_grid = new ProjectGrid();
        this._intersect_text = new IntersectText();
        this._text_overflow = new TextOverflow();

        this.setupNewContent();

    }


    private setupNewContent() {

        this._header.init();
        this._cursor.init();
        this._slideshows.init();
        
        if ( document.readyState === "complete" ) {
            
            this._project_grid.init();
            this._intersect_text.init();
            this._text_overflow.init();
            
        } else {
            
            window.addEventListener( 'load', () => {
                
                this._project_grid.init();
                this._intersect_text.init();
                this._text_overflow.init();

            } );
            
        }

        // this.updateContent();

    }


    private setupListeners() {

        window.addEventListener( 'resize', () => this.handleWindowResize() );
        // window.addEventListener('scroll', () => this.handleWindowScroll());

        // window.addEventListener( 'ajaxLinkClicked', ( event: CustomEvent ) => {

        //     this._page_transition.hideContent( event );

        // } );

        // window.addEventListener( 'ajaxSucceeded', ( event: Event ) => this.ajaxSucceeded() );
        // window.addEventListener( 'newContentInserted', () => this.setupNewContent() );
        // window.addEventListener( 'contentUpdated', ( event: CustomEvent ) => this.updateContent() );

    }


    // private updateContent() {

    //     this._history_manager.setupNewLinks();

    // }


    private ajaxSucceeded() {

        // this._home.kill();
        // this._selected_homes.kill();
        // this._case.kill();
        // this._timestamps.kill();
        // this._surveys.kill();
        // this._join.kill();

        // this._page_transition.bodyClasses = this._history_manager.response.bodyClasses;
        // this._page_transition.newContent = <HTMLElement>this._history_manager.response.markup[0];
        // this._page_transition.newMenu = <HTMLElement>this._history_manager.response.markup[1];

        // this._page_transition.showContent();

    }


    private handleWindowResize() {

        this._project_grid.resize();
        this._intersect_text.resize();
        // this._slideshows.resize();

    }

}

new Main();
