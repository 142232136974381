export class Header {

    
    private _body: HTMLElement = document.querySelector( 'body' );
    private _menu_open: boolean = false;

    constructor() {

    }


    public init() {

        const logo_link: HTMLElement = document.querySelector( '.c-header__logo__link' );
        const header: HTMLElement = document.querySelector( '.c-header' );
        if ( logo_link && header ) {

            logo_link.addEventListener( 'click', ( event: Event) => this.handleLogoLinkClicked( event, header ) );

        }
    }


    private handleLogoLinkClicked( event: Event, header: HTMLElement ) {

        event.preventDefault();
    
        this._menu_open = !this._menu_open;
        this._body.classList.toggle( 's-menu-open' );

        if ( this._menu_open ) {


        } else {

            header.scrollTo( 0, 0 );

        }
        
        return false;

    }

}